<template>
  <div fluid style="height: calc(100vh - 48px); position:relative;">
    <TopMenu :title="top_menu.title" :buttons="getButtons" />
    <router-view />
  </div>
</template>

<script>
import TopMenu from "../../components/TopMenuUniversal";

export default {
  name: "Documents",
  components: {TopMenu},
  data() {
    return {
      top_menu: {
        title: this.$t('document.title'),
      }
    }
  },
  computed: {
    getButtons() {
      let buttons = []

      buttons.push({ to: 'documents.documents', text: this.$t('document.top_menu.documents') })
      if (this.mxware.can('document-category-show')) {
        buttons.push( { to: 'documents.categories', text: this.$t('document.top_menu.categories') })
      }

      return buttons.length <= 1 ? [] : buttons
    }
  }
}
</script>


