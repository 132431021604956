<template>
  <v-row>
    <v-col cols="12" class="mb-6 ml-3 d-flex justify-space-between align-center">
      <h2 style="font-weight: 400;" class="mt-6 title">{{ title }}</h2>
      <div v-if="buttons.length > 0" class="mt-6 mr-5 crm-topmenu">
        <v-btn
            v-for="(button, i) in buttons"
            :key="i"
            :to="{name: button.to}"
            class="rounded-0"
            elevation="1"
        >
          {{ button.text}}
        </v-btn>

      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "TopMenuUniversal",
  props: {
    title: {
      type: String,
      require: true
    },
    buttons: {
      type: Array,
      default: []
    }
  }
}
</script>

<style scoped>

</style>
